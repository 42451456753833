import React from "react";
import { FormControlLabel, FormGroup, styled, Switch } from "@mui/material";
import { TOGGLE_IS_SUGGESTED_FILTER } from "../../../../store/action-types.ts";
import { DispatchType } from "../../../../store/state-type.ts";

const MaterialUISwitch = styled(Switch)(({ theme, checked }) => ({
  width: 50,
  height: 24,
  padding: 6,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(0px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        //@ts-expect-error [ignore]
        backgroundImage: `url(${process.env.PUBLIC_URL + "/lamp.svg"})`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: checked ? "#001e3c" : "#919191",
    width: 22,
    height: 22,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
      //   "#fff"
      // )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));

type SuggestedFilterType = {
  name: string;
  filter: boolean;
  dispatch: DispatchType;
  filterDisabled: boolean;
};
export default function SuggestedFilter({
  name = "Genie Mode",
  filter,
  dispatch,
  filterDisabled = false,
}: SuggestedFilterType) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<MaterialUISwitch checked={filter} />}
        label={
          <b
            style={{
              fontWeight: filter ? 400 : 500,
              fontSize: "16px",
            }}
          >
            {name}
          </b>
        }
        sx={{ mx: 0, my: "auto" }}
        onChange={(e) => {
          dispatch({
            type: TOGGLE_IS_SUGGESTED_FILTER,
            //@ts-expect-error [ignore]
            value: e.target.checked,
          });
        }}
        disabled={filterDisabled}
      />
    </FormGroup>
  );
}
