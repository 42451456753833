import axios from "../config/axios.ts";
import { DIFFICULTY_TYPE, STATUS_TYPE } from "../constants/problem-states.ts";
import {
  LOAD_PROBLEM_TABLE_LIST,
  SET_PROBLEM_TABLE_LOADING,
} from "../store/action-types.ts";
import type {
  DispatchType,
  ProblemTableListType,
  StateType,
} from "../store/state-type.ts";
import parseFilterParam from "../utils/parse-filter-param.ts";

function randomEnum<T>(anEnum: T): T[keyof T] {
  //@ts-expect-error [will remove, idk what error]
  const enumValues = Object.values(anEnum) as unknown as T[keyof T][];
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  return enumValues[randomIndex];
}

type ResponseTagType = { name: string };
type ResponseType = {
  title: string;
  question_type: string;
  reference_id: string;
  status: string;
  tags: ResponseTagType[];
  acceptance: number;
  time_taken: number;
  is_suggested: boolean;
};

function strToStatusType(status: string) {
  switch (status) {
    case "UA":
      return STATUS_TYPE.Attempted;
    case "C":
      return STATUS_TYPE.Correct;
    case "I":
      return STATUS_TYPE.Incorrect;
    default:
      return STATUS_TYPE.Todo;
  }
}

function convertResultsToList(
  resultList: ResponseType[]
): ProblemTableListType[] {
  return resultList.map((result) => {
    return {
      tags: result.tags.map((tag: ResponseTagType) => {
        return {
          name: tag.name,
        };
      }),
      title: result.title,
      status: strToStatusType(result.status),
      difficulty: randomEnum(DIFFICULTY_TYPE),
      id: result.reference_id,
      acceptance: result.acceptance,
      isSuggested: result.is_suggested,
      timeTaken: result.time_taken,
    };
  });
}

export default async function fetchTableList(
  filterParamUrl: string,
  dispatch: DispatchType,
  signal: AbortSignal
) {
  dispatch({
    type: SET_PROBLEM_TABLE_LOADING,
    value: true,
  });
  await axios
    .get(`/core/questions/?${filterParamUrl}`, {
      signal: signal,
    })
    .then((res) => {
      dispatch({
        type: LOAD_PROBLEM_TABLE_LIST,
        value: {
          count: res.data.count,
          list: convertResultsToList(res.data.results),
          // currentPage: currentPage,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
