import React from "react";
import { useNavigate } from "react-router-dom";
import party from "party-js";
import postSubmitQuestion from "../../../../handlers/submit-question.ts";
import { DispatchType, StateType } from "../../../../store/state-type.ts";
import {
  QUESTION_TYPE,
  USER_QUESTION_TYPE,
} from "../../../../constants/problem-states.ts";

import {
  Button,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

/** ICON */
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { PROBLEMS_URL } from "../../../../constants/url-path.ts";
import { UNSET_PROBLEM } from "../../../../store/action-types.ts";
import MCQOptions from "./mcq-options.tsx";

export enum SCREEN_STATE {
  SOLVED,
  UNSOLVED,
}

type ProblemSolveDialog = {
  optionSelected: number;
  setOptionSelected: React.Dispatch<React.SetStateAction<number>>;
  dispatch: DispatchType;
  problem: StateType["problem"];
  tableProblem: StateType["tableProblem"];
  isLoggedIn: boolean;
  htmlToReactParser: any;
};

function getScreenState(type?: USER_QUESTION_TYPE): SCREEN_STATE {
  switch (type) {
    case USER_QUESTION_TYPE.UNANSWERED:
      return SCREEN_STATE.UNSOLVED;
    case USER_QUESTION_TYPE.INCORRECT:
      return SCREEN_STATE.SOLVED;
    case USER_QUESTION_TYPE.CORRECT:
      return SCREEN_STATE.SOLVED;
    default:
      return SCREEN_STATE.UNSOLVED;
  }
}

export default function ProblemSolveAction({
  optionSelected,
  setOptionSelected,
  dispatch,
  problem: { details, user },
  tableProblem,
  isLoggedIn,
  htmlToReactParser,
}: ProblemSolveDialog) {
  const navigate = useNavigate();
  const textRef = React.useRef<HTMLParagraphElement | null>(null);
  const [isSubmitLoading, setSubmitLoading] = React.useState(false);

  const screenState = getScreenState(user?.type);
  const questionType = details?.questionType ?? QUESTION_TYPE.UNKOWN;

  const [subjectAnswerValue, setSubjectAnswerValue] = React.useState("");

  const handleSubmit = async () => {
    if (!isLoggedIn) return;
    if (optionSelected === -1 && subjectAnswerValue === "") return;
    if (screenState === SCREEN_STATE.SOLVED) return;

    setSubmitLoading(true);
    const selectedAnswer =
      questionType === QUESTION_TYPE.SINGLE_CORRECT
        ? details?.options[optionSelected].id ?? ""
        : subjectAnswerValue;
    const currentQuestionRefId = details?.id ?? "";
    const submitRes = await postSubmitQuestion(
      dispatch,
      currentQuestionRefId,
      selectedAnswer,
      questionType
    );
    setSubmitLoading(false);
    if (submitRes === USER_QUESTION_TYPE.CORRECT) {
      party.confetti(document.getElementById("caption-text") ?? document.body, {
        spread: 30,
        count: 60,
      });
    }
  };

  const handlePreviousClick = () => {
    if (tableProblem?.previousId) {
      dispatch({ type: UNSET_PROBLEM });
      setOptionSelected(-1);
      setSubjectAnswerValue("");
      navigate(`/${PROBLEMS_URL}/${tableProblem.previousId}`);
    }
  };
  const handleNextClick = () => {
    if (tableProblem?.nextId) {
      dispatch({ type: UNSET_PROBLEM });
      setOptionSelected(-1);
      setSubjectAnswerValue("");
      navigate(`/${PROBLEMS_URL}/${tableProblem.nextId}`);
    }
  };
  const handleSeeSolutionOnClick = () => {
    if (textRef.current) {
      textRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="space-between"
      sx={{ opacity: isLoggedIn ? 1 : 0.15 }}
    >
      <div style={{ overflowY: "scroll" }}>
        <Typography id="caption-text" component="p" variant="body1" ml={1}>
          {questionType === QUESTION_TYPE.SUBJECTIVE ? (
            "Enter your answer below (only whole numbers are accepted) :"
          ) : questionType === QUESTION_TYPE.SINGLE_CORRECT ? (
            "Select the correct option :"
          ) : (
            <Skeleton variant="text" width={"100%"} />
          )}
        </Typography>
        {questionType === QUESTION_TYPE.SUBJECTIVE ? (
          <>
            <TextField
              id="problem-subjective-input"
              placeholder="Type here..."
              sx={{ mt: 3 }}
              label={
                user?.type === USER_QUESTION_TYPE.INCORRECT
                  ? "Wrong Answer"
                  : user?.type === USER_QUESTION_TYPE.CORRECT
                  ? "Correct Answer"
                  : ""
              }
              type={
                user?.type === USER_QUESTION_TYPE.INCORRECT
                  ? "incorrect"
                  : user?.type === USER_QUESTION_TYPE.CORRECT
                  ? "correct"
                  : undefined
              }
              fullWidth
              value={
                screenState === SCREEN_STATE.SOLVED &&
                user?.postSubmit?.selectedAnswer
                  ? user?.postSubmit?.selectedAnswer
                  : subjectAnswerValue
              }
              onChange={(e) => setSubjectAnswerValue(e.target.value)}
              disabled={screenState === SCREEN_STATE.SOLVED}
            />
            {screenState === SCREEN_STATE.SOLVED &&
              user?.type === USER_QUESTION_TYPE.INCORRECT && (
                <Typography
                  id="correct-subjective-text"
                  component="p"
                  variant="body1"
                  ml={1}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <b>Correct Answer : &nbsp;</b>
                  {htmlToReactParser.parse(user.postSubmit?.correctAnswer)}
                </Typography>
              )}
          </>
        ) : (
          <MCQOptions
            optionSelected={optionSelected}
            setOptionSelected={setOptionSelected}
            htmlToReactParser={htmlToReactParser}
            screenState={screenState}
            options={details?.options}
            postSubmit={user?.postSubmit}
          />
        )}
        {screenState === SCREEN_STATE.UNSOLVED ? (
          <Grid xs={12}>
            <Button
              id="problem-submit-button"
              type="submit"
              variant="contained"
              startIcon={<TaskAltIcon />}
              sx={{
                backgroundColor: "primary.green",
                borderRadius: "1.5rem",
                borderColor: "primary.green",
                width: "fit-content",
                my: 2,
              }}
              onClick={handleSubmit}
              disabled={isSubmitLoading || details === undefined}
            >
              Submit Answer
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        {/* SOLUTION */}
        {screenState === SCREEN_STATE.SOLVED ? (
          <>
            <Typography component="p" variant="body2" ref={textRef}>
              {htmlToReactParser.parse(user?.postSubmit?.solution)}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </div>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ mt: "auto" }}
      >
        {/* <Tooltip
          title={
            screenState === SCREEN_STATE.UNSOLVED
              ? "Submit an answer first"
              : ""
          }
          placement="top-start"
        >
          <span>
            <Button
              id="see-solution-button"
              variant="text"
              endIcon={<KeyboardDoubleArrowDownIcon />}
              disabled={screenState === SCREEN_STATE.UNSOLVED}
              onClick={handleSeeSolutionOnClick}
            >
              See Solution
            </Button>
          </span>
        </Tooltip> */}
        <Button
          id="problem-previous-button"
          variant="text"
          size="large"
          startIcon={<NavigateBeforeIcon />}
          sx={{
            marginLeft: "auto!important",
            color: "primary.dark",
          }}
          onClick={handlePreviousClick}
          disabled={!!!tableProblem?.previousId}
        >
          Previous
        </Button>
        <Button
          id="problem-next-button"
          variant="text"
          size="large"
          endIcon={<NavigateNextIcon />}
          sx={{
            color: "primary.dark",
          }}
          onClick={handleNextClick}
          disabled={!!!tableProblem?.nextId}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
