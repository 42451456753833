import {
  Button,
  Stack,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { PROBLEMSET_URL } from "../../../constants/url-path.ts";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const featureList = [
  {
    imgSrc: process.env.PUBLIC_URL + "/landing-ui.png",
    title: "Simple, Easy to use UI",
    content:
      "Our intuitive platform features a simple, easy-to-navigate interface with clear filters and question lists, making it a breeze to tailor your study experience",
  },
  {
    imgSrc: process.env.PUBLIC_URL + "/landing-progress.png",
    title: "Track Your Progress",
    content:
      "See how far you've come with our progress tracker. Monitor your performance and stay motivated on your learning journey",
  },
  {
    imgSrc:
      "https://cdni.iconscout.com/illustration/premium/thumb/man-having-genie-robot-illustration-download-in-svg-png-gif-file-formats--idea-intelligence-pack-science-technology-illustrations-7622413.png?f=webp",
    title: "Get Personalised Suggestions",
    content:
      "Tailor your exam prep with our AI-powered platform. Get personalized questions based on your strengths, weaknesses, and the exam's concept weightage. Maximize your efficiency and ace in every exam.",
  },
  {
    imgSrc: process.env.PUBLIC_URL + "/landing-expert.png",
    title: "Curated Problem Sets",
    content:
      "Discover problem sets designed by experts and tailored to your exam preparation needs",
  },
];


export default function LandingPage() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const viewport = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
    viewport.setAttribute('content', 'width=device-width');
  }, [])

  return (
    <div style={{ width: "100vw" }}>
      {/* Logo Header */}
      <Grid container direction="row" pt={"5vh"}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} display={"flex"}>
          <img
            src={process.env.PUBLIC_URL + `/prep-genie-logo.svg`}
            height={"64px"}
          />
          {/* <Typography variant="h4" fontWeight={600} textAlign="left">
            PrepGenie
          </Typography> */}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>

      {/* Hero Section */}
      <Grid container direction={{ xs: "column", md: "row" }} height={"75vh"}>
        <Grid item xs={0} md={1}></Grid>
        <Grid
          item
          // xs={12}
          md={4}
          textAlign={"left"}
          justifyContent="center"
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            sx={{
              typography: { xs: "h4", md: "h2" },
              fontWeight: { xs: 600, md: 500 },
              px: { xs: 2, md: 0 },
              mt: { xs: 2, md: 0 },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Practice makes <span style={{ color: "#146BB4" }}>perfect</span>
          </Typography>
          <Typography
            sx={{
              typography: { xs: "body1", md: "h6" },
              fontWeight: { xs: 400, md: 400 },
              px: { xs: 2, md: 0 },
              textAlign: { xs: "center", md: "justify" },
            }}
            my={5}
          >
            PrepGenie is your digital study companion, with an{" "}
            <em>
              <b>Intuitive Interface</b>
            </em>{" "}
            and
            <em>
              <b> AI-powered</b>
            </em>{" "}
            progress tracking to guide you forward.
          </Typography>
          <Stack
            component={"div"}
            direction={"row"}
            display={{ xs: "flex", md: "block" }}
          >
            <Button
              variant="contained"
              size={"large"}
              sx={{ mt: 2, background: "#146BB4", mx: "auto" }}
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate(`/${PROBLEMSET_URL}`)}
            >
              CAT Problemset
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={0} md={1}></Grid>
        <Grid
          item
          md={5}
          display={"flex"}
          width={{ xs: "80%", md: "100%" }}
          sx={{ width: { xs: "80%", md: "100%" }, margin: "auto" }}
        >
          <video width="100%" loop="true" autoplay="autoplay" muted="true">
            <source
              src={process.env.PUBLIC_URL + "/product-video.mp4"}
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </Grid>
        <Grid item xs={0} md={1}></Grid>
      </Grid>
      {/* Banner */}
      <Paper
        sx={{
          backgroundColor: "#F8F8F8",
          width: "100%",
          py: { xs: 3, md: 6 },
        }}
        elevation={0}
      >
        <Typography
          sx={{ typography: { xs: "body1", md: "h5" } }}
          fontWeight={500}
          mb={5}
        >
          Kickstart your <b>CAT</b> exam preparation with <b>PrepGenie</b>
        </Typography>
        <Grid container direction={"row"} px={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Paper>
      {/* Features */}
      <Typography
        sx={{
          typography: { xs: "h5", md: "h4" },
          fontWeight: { xs: 700, md: 700 },
          px: { xs: 4, md: "auto" },
        }}
        mt={8}
      >
        <em>Daily</em> practice and <em>Focused</em> concept development
      </Typography>
      <Typography
        variant="h6"
        maxWidth={{ xs: "90%", md: "60%" }}
        mx={"auto"}
        my={3}
        fontWeight={400}
      >
        Level up your exam preparation with AI-Enhanced practice and
        Expert-Designed problemsets.
      </Typography>
      {/* <Grid container direction={"row"} spacing={8} px={6}>
        {featureList.map((feature) => (
          <Grid item xs={3} mt={3} mb={8}>
            <img src={feature.imgSrc} width={"100%"} />
            <Typography variant="h6" fontWeight={700}>
              {feature.title}
            </Typography>
            <Typography variant="body2" fontWeight={400} textAlign={"justify"}>
              {feature.content}
            </Typography>
          </Grid>
        ))}
      </Grid> */}

      {/* Features List*/}
      <Grid
        container
        direction={"column"}
        sx={{ px: { xs: 0, md: 12 } }}
        mb={8}
        mt={4}
      >
        {featureList.map((feature, idx) => (
          <Grid item mt={3} height={{ xs: "100%", md: "30vh" }}>
            <Grid
              container
              direction={{ xs: "column", md: "row" }}
              px={"15%"}
              height={"100%"}
              flexDirection={{ sm: idx % 2 ? "row-reverse" : "row" }}
            >
              <Grid item xs={5} display={"flex"} height={"100%"}>
                <img
                  src={feature.imgSrc}
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5} alignContent={"center"}>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  textAlign={{ xs: "center", md: "left" }}
                  mb={2}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  textAlign={"justify"}
                >
                  {feature.content}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Footer */}
      <Paper
        sx={{
          // minHeight: "15vh",
          //   display: "flex",
          //   flexDirection: "row",
          //   alignItems: "center",
          px: 0,
          py: 3,
        }}
        elevation={0}
      >
        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          px={{ xs: "auto", md: 12 }}
        >
          <Grid item md={5}>
            <Typography
              variant="h4"
              sx={{ color: "primary.dark" }}
              fontWeight={600}
              textAlign={{ xs: "center", md: "left" }}
              mb={{ xs: 4, md: 0 }}
            >
              PrepGenie
            </Typography>
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={3}>
            <Stack
              direction={"row"}
              justifyContent={{ xs: "center", md: "left" }}
              spacing={2}
              mb={2}
              px={{ xs: 5, md: 0 }}
            >
              <IconButton sx={{ border: 1, borderColor: "rgb(25, 118, 210)" }}>
                <FacebookIcon sx={{ color: "rgb(25, 118, 210)" }} />
              </IconButton>
              <IconButton sx={{ border: 1, borderColor: "primary.dark" }}>
                <InstagramIcon sx={{ color: "primary.dark" }} />
              </IconButton>
              <IconButton sx={{ border: 1, borderColor: "rgb(25, 118, 210)" }}>
                <LinkedInIcon sx={{ color: "rgb(25, 118, 210)" }} />
              </IconButton>
              <IconButton sx={{ border: 1, borderColor: "primary.dark" }}>
                <EmailIcon sx={{ color: "primary.dark" }} />
              </IconButton>
            </Stack>
            <Typography
              variant="body1"
              sx={{ color: "primary.dark", mt: 1 }}
              fontWeight={600}
              textAlign={{ xs: "center", md: "left" }}
            >
              Reach out to us
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "primary.dark" }}
              //   fontWeight={600}
              textAlign={"left"}
              display={"flex"}
              justifyContent={{ xs: "center", md: "left" }}
              alignItems={"center"}
              my={1}
            >
              <LocalPhoneIcon sx={{ fontSize: "inherit" }} />
              &nbsp; +91 7742479049
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "primary.dark" }}
              //   fontWeight={600}
              textAlign={{ xs: "center", md: "left" }}
              display={"flex"}
              justifyContent={{ xs: "center", md: "left" }}
              alignItems={"center"}
              my={1}
            >
              <EmailIcon sx={{ color: "primary.dark", fontSize: "inherit" }} />
              &nbsp; official@prepgenie.in
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container direction={"row"} px={12} marginTop={"auto"}>
          <Grid item xs={6}>
            <Typography variant="caption" textAlign={"left"}>
              Terms of service
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid> */}
      </Paper>
    </div>
  );
}
