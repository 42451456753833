import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  [`&.${tableCellClasses.head}`]: {
    //@ts-expect-error [including]
    ...theme.typography.tableHeader,
    //@ts-expect-error [including]
    color: theme.palette.primary.text,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: "4px",
    paddingBottom: "8px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //@ts-expect-error [including]
    backgroundColor: theme.palette.primary.lighter,
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.primary.light,
  },
  "&:hover": {
    cursor: "pointer",
  },
}));
