import { Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

type ProblemDescriptionType = {
  title: string | undefined;
  description: string | undefined;
  htmlToReactParser: any;
};
export default function ProblemDescription({
  title,
  description,
  htmlToReactParser,
}: ProblemDescriptionType) {
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h5"
        sx={{ fontWeight: "800" }}
      >
        {title ? title : <Skeleton animation="wave" width="60%" />}
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="body1"
        component="p"
        my={2}
        lineHeight="1.5rem"
      >
        {description
          ? htmlToReactParser.parse(description)
          : Array.apply(null, {
              length: 12,
            }).map((_) => <Skeleton variant="text" animation="wave" />)}
      </Typography>
    </>
  );
}
