import type {
  DifficultyListItemType,
  StateType,
  StatusListItemType,
  SubjectListItemType,
} from "./state-type";
import {
  DEFAULT_ROWS_PER_PAGE,
  DIFFICULTY_TYPE,
  STATUS_TYPE,
} from "../constants/problem-states.ts";
import { NULL_USER } from "../constants/user-constants.ts";

/** Icons **/
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";

export const subjectList: SubjectListItemType[] = [
  {
    name: "All",
    isSelected: false,
    icon: CollectionsBookmarkOutlinedIcon,
    fullName: "All Topics",
    id: "",
  },
  {
    name: "QA",
    isSelected: false,
    icon: CalculateOutlinedIcon,
    fullName: "Quantitative Ability",
    id: "",
  },
  {
    name: "LRDI",
    isSelected: false,
    icon: PsychologyOutlinedIcon,
    fullName: "Data Interpretation & Logical Reasoning",
    id: "",
  },
  {
    name: "VARC",
    isSelected: false,
    icon: HistoryEduOutlinedIcon,
    fullName: "Verbal Ability & Reading Comprehension",
    id: "",
  },
];
const difficultyList: DifficultyListItemType[] = [
  {
    name: "Easy",
    type: DIFFICULTY_TYPE.Easy,
    textColor: "primary.easy",
    isSelected: false,
  },
  {
    name: "Medium",
    type: DIFFICULTY_TYPE.Medium,
    textColor: "primary.medium",
    isSelected: false,
  },
  {
    name: "Hard",
    type: DIFFICULTY_TYPE.Hard,
    textColor: "primary.hard",
    isSelected: false,
  },
];
const statusList: StatusListItemType[] = [
  {
    index: 0,
    name: "Not attempted",
    id: "NA",
    icon: RemoveIcon,
    type: STATUS_TYPE.Todo,
    isSelected: false,
  },
  {
    index: 1,
    name: "Unanswered",
    id: "UA",
    icon: CalendarTodayOutlinedIcon,
    type: STATUS_TYPE.Attempted,
    isSelected: false,
  },
  {
    index: 2,
    name: "Correct",
    id: "C",
    icon: TaskAltOutlinedIcon,
    type: STATUS_TYPE.Correct,
    isSelected: false,
  },
  {
    index: 3,
    name: "Incorrect",
    id: "I",
    icon: CloseIcon,
    type: STATUS_TYPE.Incorrect,
    isSelected: false,
  },
];

export const initialState: StateType = {
  user: NULL_USER,
  table: {
    filters: {
      subjectList: subjectList,
      listList: [],
      topicList: [],
      patternList: [],
      difficultyList: difficultyList,
      statusList: statusList,
      searchString: "",
      isSuggested: false,
    },
    data: {
      isLoading: true,
      count: 0,
      list: [],
      currentPage: 1,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    },
  },
  problem: {
    // details: {
    //   title: "",
    //   tags: [],
    //   options: [],
    //   description: "",
    //   id: "",
    //   questionType: "",
    //   adminUrl: null,
    //   answer: "",
    // },
  },
};
