import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DispatchType, StateType } from "../../../store/state-type.ts";
import { UPDATE_PROBLEM_TABLE_PAGE_NUMBER } from "../../../store/action-types.ts";
import RowsPerPageMenu from "./filter-menu/rows-per-page-menu.tsx";
import { PROBLEMS_URL } from "../../../constants/url-path.ts";
import timestampToString from "../../../utils/timestamp-to-string.ts";
import { StyledTableCell, StyledTableRow } from "./table-components/styles.tsx";
import LoadingRow from "./table-components/loading-row.tsx";

/** MUI **/
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import TableFooter from "@mui/material/TableFooter";
import Button from "@mui/material/Button";

/** Icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AcceptanceCell from "./table-components/acceptance-cell.tsx";
import StatusCell from "./table-components/status-cell.tsx";

type ProblemTableType = {
  data: StateType["table"]["data"];
  dispatch: DispatchType;
};

function ProblemTable({ data: tableData, dispatch }: ProblemTableType) {
  const navigate = useNavigate();

  // Pagination
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    dispatch({ type: UPDATE_PROBLEM_TABLE_PAGE_NUMBER, value: newPage });
  };

  const [isRowsPerPageMenuOpen, setRowsPerPageMenuOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleRowsPerPageMenuToggle = () => {
    setRowsPerPageMenuOpen((prevOpen) => !prevOpen);
  };

  //Row Actions
  const handleProblemSelection = (problemId: string) => {
    navigate(`/${PROBLEMS_URL}/${problemId}`);
  };

  return (
    <TableContainer sx={{ borderRadius: "0.75rem" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <colgroup>
          <col width="7%" />
          <col width="70%" />
          <col width="10%" />
          <col width="13%" />
        </colgroup>

        <TableHead sx={{ borderBottom: 1 }}>
          <TableRow>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Question</StyledTableCell>
            <StyledTableCell align="center">Acceptance</StyledTableCell>
            <StyledTableCell align="right">Time Taken</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData.isLoading ? (
            <LoadingRow rowsPerPage={tableData.rowsPerPage} />
          ) : (
            tableData.list.map((row) => (
              <StyledTableRow
                key={row.id}
                onClick={() => handleProblemSelection(row.id)}
              >
                <StyledTableCell component="th" scope="row">
                  <StatusCell status={row.status} />
                </StyledTableCell>

                <StyledTableCell>
                  <Stack spacing={0.5}>
                    <Typography variant="body1" gutterBottom={false}>
                      {row.title}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {row.tags.map((tag) => {
                        return (
                          <Chip key={tag.name} label={tag.name} size="small" />
                        );
                      })}
                      {row.isSuggested && (
                        <Chip
                          key={"is-suggested"}
                          label={"Suggested"}
                          size="small"
                          icon={
                            <AutoAwesomeIcon
                              sx={{
                                ":first-child": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                          sx={{
                            background: "#00af9b",
                            color: "white",
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <AcceptanceCell acceptance={row.acceptance} />
                </StyledTableCell>

                <StyledTableCell align="right">
                  {!row.timeTaken ? "-" : timestampToString(row.timeTaken)}
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>

      <TableFooter
        sx={{
          borderTop: 1,
          display: "flex",
          justifyContent: "center",
          py: 2,
          width: "100%",
          backgroundColor: "primary.lighter",
        }}
      >
        <TableRow
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {/**@ts-expect-error [including] */}
          <Button
            ref={anchorRef}
            id="table-filter-button"
            variant="text"
            type="table-filter"
            aria-controls={
              isRowsPerPageMenuOpen ? "composition-menu" : undefined
            }
            aria-expanded={isRowsPerPageMenuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleRowsPerPageMenuToggle}
            sx={{
              flexGrow: 1,
              maxWidth: "110px",
            }}
            startIcon={<KeyboardArrowDownIcon />}
            disableElevation
          >
            {tableData.rowsPerPage} /page
          </Button>
          <Pagination
            count={Math.ceil(tableData.count / tableData.rowsPerPage)}
            page={tableData.currentPage}
            onChange={handleChangePage}
            variant="text"
            siblingCount={2}
            boundaryCount={2}
          />
        </TableRow>
        <RowsPerPageMenu
          rowsPerPage={tableData.rowsPerPage}
          dispatch={dispatch}
          anchorRef={anchorRef}
          open={isRowsPerPageMenuOpen}
          setOpen={setRowsPerPageMenuOpen}
        />
      </TableFooter>
    </TableContainer>
  );
}

export default ProblemTable;
