import React from "react";
import {
  Divider,
  Grid,
  Paper,
  Slider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import getTopicWiseStrength from "../../../handlers/topic-wise-progress.ts";
import { subjectList } from "../../../store/initial-state.ts";

/** ICONS */
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation } from "react-router-dom";
import { PROBLEMSET_URL } from "../../../constants/url-path.ts";

type TopicStrengthCardType = {
  index: number;
  topicName: string;
  strengthPercentage: number;
  unansweredValue: number;
  correctValue: number;
  incorrectValue: number;
  totalValue: number;
};

function setSliderColor(value: number) {
  if (value <= 35) return "primary.hard";
  if (value >= 65) return "primary.easy";
  return "primary.medium";
}

function TopicStrengthCard({
  index,
  topicName,
  strengthPercentage,
  unansweredValue,
  correctValue,
  incorrectValue,
  totalValue,
}: TopicStrengthCardType) {
  return (
    <Paper
      elevation={0}
      sx={{
        pt: 1,
        px: 2,
        backgroundColor: index % 2 === 0 ? "primary.light" : "primary.lighter",
      }}
    >
      <Stack direction={"row"} alignItems={"flex-start"} spacing={1} mb={1}>
        <Typography variant="caption" textAlign={"left"} fontWeight={500}>
          {index}.
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign={"left"}
          fontWeight={500}
          sx={{
            ":hover": {
              textDecoration: "underline",
              color: "primary.main",
              cursor: "pointer",
            },
          }}
        >
          {topicName}
        </Typography>
      </Stack>
      <Grid container>
        <Grid item xs={5}>
          <Slider
            disabled
            value={strengthPercentage}
            aria-label="Topic Strength Slider"
            sx={{
              color: totalValue
                ? setSliderColor(strengthPercentage)
                : "#00000001",
              height: "2px",
              "& .MuiSlider-thumb": { display: "none" },
              "&.Mui-disabled .MuiSlider-track": {
                color: totalValue
                  ? setSliderColor(strengthPercentage)
                  : "#00000001",
              },
            }}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid
              item
              xs={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <CalendarTodayOutlinedIcon
                sx={{ fontSize: "1rem", color: "primary.medium" }}
              />
              <Typography variant="subtitle2">{unansweredValue}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <HighlightOffIcon
                sx={{ fontSize: "1rem", color: "primary.hard", mr: 0.5 }}
              />
              <Typography variant="subtitle2">{incorrectValue}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <TaskAltOutlinedIcon
                sx={{ fontSize: "1rem", color: "primary.easy" }}
              />
              <Typography variant="subtitle2">{correctValue}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
type TopicListType = {
  topicName: string;
  topicId: string;
  strengthPercentage: number;
  unansweredValue: number;
  correctValue: number;
  incorrectValue: number;
  totalValue: number;
};
export type CombinedTopicsListType = {
  QA: TopicListType[];
  VARC: TopicListType[];
  LRDI: TopicListType[];
};

type TopicWiseStrengthType = {
  isLoggedIn: boolean;
};
export default function TopicWiseStrength({
  isLoggedIn,
}: TopicWiseStrengthType) {
  const location = useLocation();
  /** TABS */
  const [tabValue, setTabValue] = React.useState(0);
  const keyValueMap = ["QA", "LRDI", "VARC"];
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [QA, LRDI, VARC] = [subjectList[1], subjectList[2], subjectList[3]];

  const [topicsList, setTopicsList] = React.useState<CombinedTopicsListType>({
    QA: [],
    LRDI: [],
    VARC: [],
  });
  const selectedTopicList = topicsList[keyValueMap[tabValue]];

  /** API CALL */
  React.useEffect(() => {
    async function makeAPIcall() {
      const res = await getTopicWiseStrength();
      if (res) setTopicsList(res);
    }

    if (isLoggedIn) {
      if (
        [`/${PROBLEMSET_URL}`, `/${PROBLEMSET_URL}/`].includes(
          location.pathname
        )
      )
        makeAPIcall();
    } else {
      setTopicsList({
        QA: [],
        LRDI: [],
        VARC: [],
      });
    }
  }, [isLoggedIn, location.pathname]);

  return (
    <>
      <Paper
        sx={{
          mt: 3,
          pt: 2,
          pb: 2,
          position: "relative",
        }}
        elevation={2}
      >
        <Typography
          variant="subtitle2"
          gutterBottom
          textAlign={"left"}
          ml={2}
          mb={1}
        >
          💪 Topic-wise Strength
        </Typography>
        <Divider variant="fullWidth" sx={{ mt: 0, mx: 2 }} />
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="icon tabs example"
          sx={{ px: 2, mb: 2 }}
          variant="fullWidth"
        >
          <Tab icon={<QA.icon />} aria-label={QA.name} label={QA.name} />
          <Tab icon={<LRDI.icon />} aria-label={LRDI.name} label={LRDI.name} />
          <Tab icon={<VARC.icon />} aria-label={VARC.name} label={VARC.name} />
        </Tabs>
        <div style={{ height: "320px", overflowY: "scroll" }}>
          {isLoggedIn ? (
            selectedTopicList.map((list, listIdx) => {
              return (
                <>
                  <TopicStrengthCard
                    index={listIdx + 1}
                    topicName={list.topicName}
                    incorrectValue={list.incorrectValue}
                    correctValue={list.correctValue}
                    unansweredValue={list.unansweredValue}
                    strengthPercentage={list.strengthPercentage}
                    totalValue={list.totalValue}
                  />
                  {listIdx + 1 !== selectedTopicList.length && (
                    <Divider variant="fullWidth" sx={{ mt: 0 }} />
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: "0",
                left: "0",
                backgroundColor: "#ffffff95",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
            >
              <LockIcon sx={{ fontSize: "32px" }} />
              <Typography variant="body1" fontWeight={500} mt={1}>
                Sign in to view your Topic-wise Strength
              </Typography>
            </div>
          )}
        </div>
      </Paper>
    </>
  );
}
