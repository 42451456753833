import { USER_QUESTION_TYPE } from "../constants/problem-states.ts";

export default function getUserQuestionType(type?: string): USER_QUESTION_TYPE {
  switch (type) {
    case "U":
      return USER_QUESTION_TYPE.UNANSWERED;
    case "I":
      return USER_QUESTION_TYPE.INCORRECT;
    case "C":
      return USER_QUESTION_TYPE.CORRECT;
    default:
      return USER_QUESTION_TYPE.UNKOWN;
  }
}
