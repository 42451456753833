import * as React from 'react';
import firebaseConfig from '../../config/firebase-config.ts';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import postUserSignIn from '../../handlers/user-login.ts';

firebase.initializeApp(firebaseConfig);

function CustomGoogleLogin({ dispatch }) {

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
    
        firebase
            .auth()
            .signInWithPopup(provider)
            .then(result => {
                console.log(result)
                postUserSignIn(dispatch, result.user.uid);
            })
            .catch(e => {
                console.log(e.message);
            });
    };

    return (
        <div style = {{width: '175px', height: '40px', cursor: 'pointer'}} onClick={signInWithGoogle}>
            <img src={require('../../assets/web_light_rd_SI@4x.png')} alt = 'Google Login' style={{width: '100%', height: "100%"}} />
        </div>
    )
}

export default CustomGoogleLogin;
