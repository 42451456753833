import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { UPDATE_SUBJECT_FILTER_ONCLICK } from "../../../store/action-types.ts";
import {
  DispatchType,
  SubjectListItemType,
} from "../../../store/state-type.ts";

type SubjectButtonGroupType = {
  subjectList: SubjectListItemType[];
  dispatch: DispatchType;
};
function SubjectButtonGroup({ subjectList, dispatch }: SubjectButtonGroupType) {
  const handleClick = (idx: number) => {
    dispatch({
      type: UPDATE_SUBJECT_FILTER_ONCLICK,
      index: idx,
    });
  };
  return (
    <Stack direction="row" spacing={2}>
      {subjectList.map((subject, idx) => (
        //@ts-expect-error [including 'type' prop]
        <Button
          key={subject.name}
          id={`subject-button-filter`}
          // eslint-disable-next-line
          variant={subject.isSelected == true ? "contained" : "text"}
          type="subject-filter"
          size="large"
          onClick={() => handleClick(idx)}
          startIcon={
            <subject.icon
              id={`subject-button-filter`}
              sx={{ fontSize: "28px!important" }}
            />
          }
          sx={{
            "& .MuiButton-startIcon": {
              margin: 0,
              mb: 1,
              padding: "6px",
              borderRadius: 1,
              background: subject.isSelected ? "#00000010" : "none",
            },
          }}
        >
          {subject.fullName}
        </Button>
      ))}
    </Stack>
  );
}
export default SubjectButtonGroup;
