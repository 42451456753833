import React from "react";
import {
  ProblemDetailsType,
  ProblemUserType,
} from "../../../../store/state-type";
import timestampToString from "../../../../utils/timestamp-to-string.ts";
import { useStopwatch } from "react-timer-hook";
import { Button, Chip, IconButton, Skeleton, Stack } from "@mui/material";

/** ICONS */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BugReportIcon from "@mui/icons-material/BugReport";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { useParams } from "react-router-dom";
import { SCREEN_STATE } from "./problem-solve-side.tsx";
import { USER_QUESTION_TYPE } from "../../../../constants/problem-states.ts";

type ProblemDialogHeaderType = {
  handleDialogClose: () => void;
  problemUser: ProblemUserType | undefined;
  tags: ProblemDetailsType["tags"] | undefined;
};

function getScreenState(type?: USER_QUESTION_TYPE): SCREEN_STATE {
  switch (type) {
    case USER_QUESTION_TYPE.UNANSWERED:
      return SCREEN_STATE.UNSOLVED;
    case USER_QUESTION_TYPE.INCORRECT:
      return SCREEN_STATE.SOLVED;
    case USER_QUESTION_TYPE.CORRECT:
      return SCREEN_STATE.SOLVED;
    default:
      return SCREEN_STATE.UNSOLVED;
  }
}

export default function ProblemDialogHeader({
  handleDialogClose,
  problemUser,
  tags,
}: ProblemDialogHeaderType) {
  const params = useParams();
  const problemId = params.problemId;
  const elapsedTime = problemUser ? problemUser.timeTaken : -1;

  const screenState = getScreenState(problemUser?.type);

  const { totalSeconds, seconds, minutes, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  React.useEffect(() => {
    if (problemId && elapsedTime !== -1) {
      reset();
    }
  }, [problemId, elapsedTime]);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <IconButton aria-label="Share Button" onClick={handleDialogClose}>
        <ArrowBackIcon sx={{ color: "primary.dark" }} />
      </IconButton>
      <Chip
        label={
          <Stack direction="row">
            <TimerOutlinedIcon sx={{ fontSize: "16px", pr: 1, my: "auto" }} />
            {elapsedTime === -1
              ? timestampToString(0)
              : screenState === SCREEN_STATE.SOLVED
              ? timestampToString(elapsedTime)
              : timestampToString(totalSeconds + elapsedTime)}
          </Stack>
        }
        size="medium"
        sx={{
          backgroundColor: "primary.dark",
          color: "primary.contrastText",
        }}
      />
      {tags
        ? tags.map((tag) => {
            return <Chip label={tag.name} size="medium" />;
          })
        : Array.apply(null, {
            length: 3,
          }).map((_) => (
            <Skeleton>
              <Chip label="Loading" size="medium" />
            </Skeleton>
          ))}
      <Button
        id="problem-share-button"
        variant="text"
        startIcon={<BugReportIcon />}
        sx={{
          color: "primary.hard",
          marginLeft: "auto!important",
        }}
      >
        Report a Bug
      </Button>
    </Stack>
  );
}
