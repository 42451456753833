import React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

export default function Footer() {
  return (
    <>
      <Divider variant="fullWidth" sx={{ color: "primary.dark", mt: 10 }} />
      <Paper
        sx={{
          height: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          px: 10,
        }}
        elevation={0}
      >
        <Typography variant="body2" sx={{ color: "primary.dark" }}>
          Copyright @ 2024 PrepGenie
        </Typography>
      </Paper>
    </>
  );
}
