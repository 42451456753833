import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import NavBar from "./components/common/navbar.tsx";
import ProblemsetMain from "./components/pages/problemset/problemset-main.tsx";

import { reducer } from "./store/reducer.ts";
import { initialState } from "./store/initial-state.ts";
import Footer from "./components/common/footer.tsx";
import { StateType } from "./store/state-type.ts";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ROWS_PER_PAGE,
} from "./constants/problem-states.ts";
import pingPong from "./handlers/ping-pong.ts";
import LandingPage from "./components/pages/landing/LandingPage.tsx";

function createInitialState(initState: StateType): StateType {
  const urlParams = new URLSearchParams(window.location.search);
  const rowsPerPageParam: number = parseInt(
    urlParams.get("page_size") ?? "null"
  );
  const pageParam: number = parseInt(urlParams.get("page") ?? "null");
  return {
    ...initState,
    table: {
      ...initState.table,
      data: {
        ...initState.table.data,
        rowsPerPage: rowsPerPageParam
          ? rowsPerPageParam
          : DEFAULT_ROWS_PER_PAGE,
        currentPage: pageParam ? pageParam : DEFAULT_PAGE_NUMBER,
      },
    },
  };
}

function App() {
  const [state, dispatch] = React.useReducer(
    reducer,
    initialState,
    createInitialState
  );

  React.useEffect(() => {
    if (state.user.isLoading) pingPong(dispatch);
  }, [state.user.isLoading]);

  // React.useEffect(() => {
  //   if (state.user.isLoggedIn) {
  //     //fetch
  //   } else {
  //     //logout
  //   }
  // }, [state.user.isLoggedIn]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/problemset"
          element={
            <>
              <NavBar user={state.user} dispatch={dispatch} />
              <ProblemsetMain
                table={state.table}
                problemDetails={state.problem}
                user={state.user}
                dispatch={dispatch}
              />
              <Footer />
            </>
          }
        />
        <Route
          path="/problem/:problemId"
          element={
            <>
              <NavBar user={state.user} dispatch={dispatch} />
              <ProblemsetMain
                table={state.table}
                problemDetails={state.problem}
                user={state.user}
                dispatch={dispatch}
                tableProblem={state.tableProblem}
              />
              <Footer />
            </>
          }
        />
        <Route path="/" element={<LandingPage />} />
        {/* Redirect any other route to "/temp" */}
        <Route path="*" element={<Navigate to="/problemset" replace />} />
      </Routes>
    </div>
  );
}

export default App;
