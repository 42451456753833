import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ROWS_PER_PAGE,
} from "../constants/problem-states.ts";
import { NULL_USER } from "../constants/user-constants.ts";
import {
  LOAD_PROBLEM_DETAILS,
  LOAD_PROBLEM_FILTER_DETAILS,
  LOAD_PROBLEM_TABLE_LIST,
  LOGIN_USER,
  LOGOUT_USER,
  SELECT_DIFFICULTY_ITEM_FILTER,
  SELECT_LIST_ITEM_FILTER,
  SELECT_PATTERN_ITEM_FILTER,
  SELECT_ROWS_PER_PAGE_ITEM_FILTER,
  SELECT_STATUS_ITEM_FILTER,
  SELECT_TOPIC_ITEM_FILTER,
  SET_IS_LOGIN_VALUE,
  SET_ONCLICK_PROBLEM_NEXT_PREVIOUS,
  SET_PROBLEM_TABLE_LOADING,
  SET_PROBLEM_USER,
  SET_USER,
  TOGGLE_IS_SUGGESTED_FILTER,
  UNSELECT_ALL_TOPIC_FILTER,
  UNSET_PROBLEM,
  UPDATE_PROBLEM_TABLE_PAGE_NUMBER,
  UPDATE_SEARCH_FILTER,
  UPDATE_SUBJECT_FILTER_ONCLICK,
} from "./action-types.ts";
import type {
  ActionType,
  ProblemTableListType,
  StateType,
  SubjectListItemType,
  TableProblemType,
} from "./state-type.ts";

function updateIndex(list: any[], index: number, updatedItem: any) {
  list[index] = updatedItem;
  return list;
}

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case UPDATE_SUBJECT_FILTER_ONCLICK: {
      const subjectList: SubjectListItemType[] =
        state.table.filters.subjectList;
      const idx: number = action.index ?? -1;

      var newItem: SubjectListItemType = {
        ...subjectList[idx],
        isSelected: !subjectList[idx].isSelected,
      };
      var newSubjectList: SubjectListItemType[] = subjectList;
      var newSubjectParam: string[] = [];

      if (idx === 0) {
        if (newItem.isSelected) {
          newSubjectList = newSubjectList.map((subject, subjectIdx) => {
            return subjectIdx === 0
              ? { ...subject, isSelected: true }
              : { ...subject, isSelected: false };
          });
          newSubjectParam = [];
        } else {
          //...doNothing
          return state;
        }
      } else {
        newSubjectList = updateIndex(newSubjectList, idx, newItem);
        if (newItem.isSelected) {
          newSubjectList = updateIndex(newSubjectList, 0, {
            ...subjectList[0],
            isSelected: false,
          });
          newSubjectParam.push(newItem.id);
        } else {
          newSubjectParam = newSubjectParam.filter((val) => val !== newItem.id);
          var isAnySelected: boolean = false;
          newSubjectList.forEach(
            (subject) => (isAnySelected ||= subject.isSelected)
          );
          if (!isAnySelected) {
            newSubjectList = updateIndex(newSubjectList, 0, {
              ...subjectList[0],
              isSelected: true,
            });
            newSubjectParam = [];
          }
        }
      }

      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            subjectList: newSubjectList,
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SELECT_DIFFICULTY_ITEM_FILTER: {
      const oldList = state.table.filters.difficultyList;
      const idx: number = action.index ?? -1;
      const newItem = { ...oldList[idx], isSelected: !oldList[idx].isSelected };
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            difficultyList: updateIndex(oldList, idx, newItem),
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SELECT_PATTERN_ITEM_FILTER: {
      const oldList = state.table.filters.patternList;
      const idx: number = action.index ?? -1;
      const newItem = { ...oldList[idx], isSelected: !oldList[idx].isSelected };
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            patternList: updateIndex(oldList, idx, newItem),
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SELECT_TOPIC_ITEM_FILTER: {
      const oldList = state.table.filters.topicList;
      const idx: number = action.index ?? -1;
      const newItem = { ...oldList[idx], isSelected: !oldList[idx].isSelected };
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            topicList: updateIndex(oldList, idx, newItem),
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case UNSELECT_ALL_TOPIC_FILTER: {
      const oldList = state.table.filters.topicList;
      const newList = oldList.map((list) => {
        return { ...list, isSelected: false };
      });
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            topicList: newList,
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SELECT_LIST_ITEM_FILTER: {
      const oldList = state.table.filters.listList;
      const idx: number = action.index ?? -1;
      const newItem = { ...oldList[idx], isSelected: !oldList[idx].isSelected };
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            listList: updateIndex(oldList, idx, newItem),
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SELECT_STATUS_ITEM_FILTER: {
      const oldList = state.table.filters.statusList;
      const idx: number = action.index ?? -1;
      const newItem = { ...oldList[idx], isSelected: !oldList[idx].isSelected };
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            statusList: updateIndex(oldList, idx, newItem),
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case TOGGLE_IS_SUGGESTED_FILTER: {
      const newValue: boolean = action.value;
      // const newValue = !state.table.filters.isSuggested;
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            isSuggested: newValue,
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case UPDATE_SEARCH_FILTER: {
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            searchString: action.value,
          },
          data: {
            ...state.table.data,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SELECT_ROWS_PER_PAGE_ITEM_FILTER: {
      const newRowsPerPageValue: number = action.value ?? DEFAULT_ROWS_PER_PAGE;
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            ...state.table.data,
            rowsPerPage: newRowsPerPageValue,
            currentPage: DEFAULT_PAGE_NUMBER,
          },
        },
      };
    }
    case SET_PROBLEM_TABLE_LOADING: {
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            ...state.table.data,
            isLoading: action.value,
          },
        },
      };
    }
    case LOAD_PROBLEM_TABLE_LIST: {
      const problemList: ProblemTableListType[] = action.value.list;
      const problemCount: number = action.value.count;
      // const newPage: number = action.value.currentPage;
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            ...state.table.data,
            count: problemCount,
            list: problemList,
            // currentPage: newPage,
            isLoading: false,
          },
        },
      };
    }
    case UPDATE_PROBLEM_TABLE_PAGE_NUMBER: {
      const newPage: number = action.value;
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            ...state.table.data,
            currentPage: newPage,
          },
        },
      };
    }
    case LOAD_PROBLEM_DETAILS: {
      return {
        ...state,
        problem: {
          details: action.value.details,
          user: action.value.user,
        },
      };
    }
    case SET_PROBLEM_USER: {
      return {
        ...state,
        problem: {
          ...state.problem,
          user: action.value,
        },
      };
    }
    case UNSET_PROBLEM: {
      return {
        ...state,
        problem: {
          user: undefined,
          details: undefined,
        },
      };
    }
    case LOAD_PROBLEM_FILTER_DETAILS: {
      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            ...state.table.filters,
            listList: action.value.listList,
            topicList: action.value.topicList,
            patternList: action.value.patternList,
            subjectList: action.value.subjectList,
          },
        },
      };
    }
    case SET_ONCLICK_PROBLEM_NEXT_PREVIOUS: {
      const problemId: string = action.value;
      var tableProblem: TableProblemType = {
        previousId: null,
        currentId: problemId,
        nextId: null,
      };
      const tableList = state.table.data.list;

      if (tableList.length == 0) return state;

      tableList.forEach((list, listIdx) => {
        if (list.id === problemId) {
          tableProblem.previousId =
            listIdx - 1 >= 0 ? tableList[listIdx - 1].id : null;
          tableProblem.nextId =
            listIdx + 1 < tableList.length ? tableList[listIdx + 1].id : null;
        }
      });

      return {
        ...state,
        tableProblem: tableProblem,
      };
    }
    // <---USER--->
    case SET_IS_LOGIN_VALUE: {
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          isLoggedIn: action.value,
        },
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          userDetails: action.value,
        },
      };
    }
    case LOGIN_USER: {
      return {
        ...state,
        user: action.value,
      };
    }
    case LOGOUT_USER: {
      return {
        ...state,
        user: NULL_USER,
      };
    }
    default:
      throw Error("Unknown action.");
  }
}
