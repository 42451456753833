import React from "react";
import SubjectButtonGroup from "./subject-button-group.tsx";
import ProgressCard from "./progress-card.tsx";
import ProblemsetFilter from "./problemset-filters.tsx";
import ProblemTable from "./problem-table.tsx";
import fetchTableList from "../../../handlers/fetch-problem-table-list.ts";
import type { DispatchType, StateType } from "../../../store/state-type.ts";
import fetchProblemTableFilterDetails from "../../../handlers/fetch-problem-table-filter-content.ts";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import parseFilterParam from "../../../utils/parse-filter-param.ts";
import ProblemModalMCQV2 from "./problem-modal/problem-modal.tsx";
import SelectedFilterList from "./selected-filter-list.tsx";

/** MUI */
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import { SET_ONCLICK_PROBLEM_NEXT_PREVIOUS } from "../../../store/action-types.ts";
import TopicWiseStrength from "./topic-strength.tsx";
import DailyCheckinCalendar from "./analytics/daily-checkin-calendar.tsx";

type ProblemsetMainType = {
  table: StateType["table"];
  problemDetails: StateType["problem"];
  user: StateType["user"];
  tableProblem?: StateType["tableProblem"];
  dispatch: DispatchType;
};
function ProblemsetMain({
  table,
  problemDetails,
  user,
  tableProblem,
  dispatch,
}: ProblemsetMainType) {
  const params = useParams();
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const problemId = params.problemId;

  const filterParamUrl = parseFilterParam(
    table.data.rowsPerPage,
    table.data.currentPage,
    table.filters.subjectList,
    table.filters.listList,
    table.filters.topicList,
    table.filters.patternList,
    table.filters.statusList,
    table.filters.searchString,
    table.filters.isSuggested
  );

  const [isOpen, setIsOpen] = React.useState(false);

  /** Handle Route Changes */
  React.useEffect(() => {
    if (problemId) {
      setIsOpen(true);
      dispatch({ type: SET_ONCLICK_PROBLEM_NEXT_PREVIOUS, value: problemId });
    } else {
      setIsOpen(false);
    }
  }, [params]);

  /** Fetch Filters */
  React.useEffect(() => {
    fetchProblemTableFilterDetails(dispatch, searchParams);
  }, []);

  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key === name) {
            return decodeURIComponent(value);
        }
    }
    return null;
  }

  React.useEffect(() => {
    const viewport = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
    viewport.setAttribute('content', 'width=device-width,width=1024');
  }, [])

  /** Fetch Table Data */
  React.useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      try {
        // Fetch data with the signal
        navigate(
          `?${filterParamUrl
            .split("&")
            .filter((param) => !param.startsWith("search="))
            .filter((param) => !param.startsWith("user_question_status="))
            .filter((param) => !param.startsWith("is_suggested="))
            .join("&")}`
        );
        await fetchTableList(filterParamUrl, dispatch, signal);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Fetch error:", error);
        }
      }
    };

    if (!user.isLoading && true) fetchData();
    return () => {
      controller.abort();
    };
  }, [
    table.data.currentPage,
    table.data.rowsPerPage,
    table.filters,
    user.isLoading,
  ]);

  /**
   * Dialog's requirements
   */
  const handleDialogClose = () => {
    navigate(
      `/problemset/?${filterParamUrl
        .split("&")
        .filter((param) => !param.startsWith("search="))
        .join("&")}`
    );
  };
  return (
    <>
      <Box sx={{ py: 2 }}>
        <Grid container spacing={4} width="100%">
          <Grid xs={1} xl={2}></Grid>
          <Grid xs={10} md={8} xl={6.5}>
            <SubjectButtonGroup
              subjectList={table.filters.subjectList}
              dispatch={dispatch}
            />
            <ProblemsetFilter
              filters={table.filters}
              dispatch={dispatch}
              isUserLoggedIn={user.isLoggedIn}
            />
            <SelectedFilterList filters={table.filters} dispatch={dispatch} />
            <ProblemTable data={table.data} dispatch={dispatch} />
          </Grid>
          <Grid xs={10} sm={6} md={2.7} xl={2} pr={0} mx={"auto"}>
            <ProgressCard isLoggedIn={user.isLoggedIn} />
            {/* <DailyCheckinCalendar /> */}
            <TopicWiseStrength isLoggedIn={user.isLoggedIn} />
          </Grid>
          <Grid xs={0} md={0.3} xl={1.5} />
        </Grid>
      </Box>
      <ProblemModalMCQV2
        dispatch={dispatch}
        problem={problemDetails}
        tableProblem={tableProblem}
        isOpen={isOpen}
        handleDialogClose={handleDialogClose}
        problemId={problemId ?? null}
        isLoggedIn={user.isLoggedIn}
      />
    </>
  );
}
export default ProblemsetMain;
