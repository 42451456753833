import axios from "../config/axios.ts";
import { SET_IS_LOGIN_VALUE } from "../store/action-types.ts";
import type { DispatchType } from "../store/state-type.ts";
import getUserDetails from "./fetch-user-details.ts";

export default function pingPong(dispatch: DispatchType) {
  axios
    .get(`/users/check_login/`)
    .then((res) => {
      dispatch({
        type: SET_IS_LOGIN_VALUE,
        value: true,
      });
      getUserDetails(dispatch);
    })
    .catch((err) => {
      dispatch({
        type: SET_IS_LOGIN_VALUE,
        value: false,
      });
      console.log(err);
    });
}
