import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DispatchType } from "../../store/state-type";
import { UPDATE_SEARCH_FILTER } from "../../store/action-types.ts";
import debounce from "lodash.debounce";

type SearchInputType = {
  dispatch: DispatchType;
  placeholder: string;
  searchFilter: string;
};
function SearchInput({
  placeholder = "Search",
  searchFilter,
  dispatch,
}: SearchInputType) {
  const [filter, setFilter] = React.useState("");
  const fetchSearchResults = async (query: string) => {
    // Replace with your API call logic
    dispatch({ type: UPDATE_SEARCH_FILTER, value: query });
  };
  // Debounce the API call
  const debounceSearch = React.useCallback(
    debounce((nextValue: string) => fetchSearchResults(nextValue), 600),
    [] // Empty dependency array ensures debounce function is created only once
  );

  function handleSearch(val: string) {
    setFilter(val);
    debounceSearch(val);
  }
  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        p: "0px 4px",
        display: "flex",
        alignItems: "center",
        flexGrow: 2,
        borderRadius: "5px",
        border: 1,
      }}
    >
      <IconButton
        type="button"
        sx={{ mx: 1, px: 0 }}
        aria-label="search"
        size="small"
      >
        <SearchIcon sx={{ fontSize: "medium" }} />
      </IconButton>
      <InputBase
        sx={{ flex: 1, fontSize: "small" }}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder.toLocaleLowerCase() }}
        size="small"
        value={filter}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </Paper>
  );
}
export default SearchInput;
