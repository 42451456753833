import React from "react";
import { Divider, Stack } from "@mui/material";
import ProgressPieChart from "../../common/progress-pie-chart";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import getUserProgress from "../../../handlers/user-progress.ts";
import { useLocation, useParams } from "react-router-dom";
import { PROBLEMSET_URL } from "../../../constants/url-path.ts";
import LockIcon from "@mui/icons-material/Lock";

export type UserProgressType = {
  incorrect: number | null;
  correct: number | null;
  not_answered: number | null;
};

type ProgressCardType = { isLoggedIn: boolean };

export const initProgressValue = {
  incorrect: null,
  correct: null,
  not_answered: null,
};
export default function ProgressCard({ isLoggedIn }: ProgressCardType) {
  const location = useLocation();
  const [value, setValues] =
    React.useState<UserProgressType>(initProgressValue);

  React.useEffect(() => {
    async function makeAPIcall() {
      const res = await getUserProgress();
      setValues(res);
    }
    if (isLoggedIn) {
      if (
        [`/${PROBLEMSET_URL}`, `/${PROBLEMSET_URL}/`].includes(
          location.pathname
        )
      )
        makeAPIcall();
    } else {
      setValues(initProgressValue);
    }
  }, [isLoggedIn, location.pathname]);

  return (
    <Paper
      elevation={2}
      sx={{ p: 2, position: "relative", borderRadius: "4px" }}
      id="progress-card-paper"
    >
      <Typography variant="subtitle2" gutterBottom textAlign={"left"}>
        🚀 Your Progress
      </Typography>
      <Divider variant="fullWidth" sx={{ my: 1, mb: 2 }} />
      <Stack direction="row">
        <ProgressPieChart radius={50} value={value} />
        <Stack
          height="inherit"
          justifyContent="center"
          textAlign="left"
          spacing={2}
          width="fit-content"
          mx={"auto"}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: "primary.easy" }}>
              Correct
            </Typography>
            <Typography variant="body2" pl={3}>
              {value.correct && value.correct >= 1 ? value.correct : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: "primary.hard" }}>
              Incorrect
            </Typography>
            <Typography variant="body2" pl={3}>
              {value.incorrect && value.incorrect >= 1 ? value.incorrect : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: "primary.medium" }}>
              Not Answered
            </Typography>
            <Typography variant="body2" pl={3}>
              {value.not_answered && value.not_answered >= 1
                ? value.not_answered
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {!isLoggedIn ? (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            backgroundColor: "#ffffff95",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
          }}
        >
          <LockIcon sx={{ fontSize: "32px" }} />
          <Typography variant="body1" fontWeight={500} mt={1}>
            Sign in to view your progress
          </Typography>
        </div>
      ) : (
        <></>
      )}
    </Paper>
  );
}
