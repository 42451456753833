import React from "react";
import {
  DispatchType,
  PatternListItemType,
} from "../../../../store/state-type";
import { SELECT_PATTERN_ITEM_FILTER } from "../../../../store/action-types.ts";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";

type PatternMenuType = {
  patternList: PatternListItemType[];
  dispatch: DispatchType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.RefObject<HTMLButtonElement>;
};

export default function PatternMenu({
  patternList,
  dispatch,
  open,
  setOpen,
  anchorRef,
}: PatternMenuType) {
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleOnClickMenuItem = (
    event: Event | React.SyntheticEvent,
    listItemIdx: number
  ) => {
    dispatch({
      type: SELECT_PATTERN_ITEM_FILTER,
      index: listItemIdx,
    });
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{ zIndex: 5 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper sx={{ my: 0.5 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="list-menu"
                aria-labelledby="list-button"
                onKeyDown={handleListKeyDown}
                sx={{ width: "172px" }}
              >
                {patternList.map((listItem) => {
                  return (
                    <MenuItem
                      key={listItem.id}
                      onClick={(e) => handleOnClickMenuItem(e, listItem.index)}
                      selected={listItem.isSelected}
                      disableRipple
                    >
                      <Typography variant="body1" noWrap mr={1}>
                        {listItem.name}
                      </Typography>
                      {listItem.isSelected && (
                        <CheckIcon
                          sx={{
                            ml: "auto",
                            color: "primary.main",
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
