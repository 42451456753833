import axios from "../config/axios.ts";
import { LOGOUT_USER } from "../store/action-types.ts";
import type { DispatchType } from "../store/state-type.ts";

export default function logOutUser(dispatch: DispatchType) {
  axios
    .post(`/users/logout/`)
    .then((res) => {
      dispatch({ type: LOGOUT_USER });
    })
    .catch((err) => {
      console.log(err);
    });
}
