import { QUESTION_TYPE } from "../constants/problem-states.ts";

export default function getQuestionType(type: string): QUESTION_TYPE {
  switch (type) {
    case "SU":
      return QUESTION_TYPE.SUBJECTIVE;
    case "SC":
      return QUESTION_TYPE.SINGLE_CORRECT;
    default:
      return QUESTION_TYPE.UNKOWN;
  }
}
