import React from "react";
import fetchProblemDetails from "../../../../handlers/fetch-problem-details.ts";
import { DispatchType, StateType } from "../../../../store/state-type.ts";
import ProblemDialogHeader from "./dialog-header.tsx";
import ProblemDescription from "./problem-description.tsx";
import ProblemSolveAction from "./problem-solve-side.tsx";
import { Typography } from "@mui/material";

/** MUI */
import Grid from "@mui/material/Unstable_Grid2";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import { UNSET_PROBLEM } from "../../../../store/action-types.ts";
import LockIcon from "@mui/icons-material/Lock";
import CustomGoogleLogin from "../../../common/google-login.js";

//@ts-expect-error [Lite lo]
const HtmlToReactParser = require("html-to-react").Parser;

type ProblemModalMCQV2Type = {
  /** Dialog */
  isOpen: boolean;
  handleDialogClose: () => void;
  /** Problem */
  dispatch: DispatchType;
  problem: StateType["problem"];
  tableProblem: StateType["tableProblem"];
  problemId: string | null;
  isLoggedIn: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProblemModalMCQV2({
  isOpen,
  handleDialogClose,
  dispatch,
  problem,
  tableProblem,
  problemId,
  isLoggedIn,
}: ProblemModalMCQV2Type) {
  const htmlToReactParser = new HtmlToReactParser();

  const { details } = problem;
  const [optionSelected, setOptionSelected] = React.useState(-1);

  /** Fetch Problem Details */
  React.useEffect(() => {
    if (problemId) {
      fetchProblemDetails(dispatch, problemId, true);
    }
  }, [problemId]);

  /** Actions */
  const handleClose = () => {
    setOptionSelected(-1);
    handleDialogClose();
    dispatch({ type: UNSET_PROBLEM });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="problem-modal-title"
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          height: "calc(100vh - 50px - 10vh)",
          p: 2,
          width: "100%",
          maxWidth: "85vw",
        },
      }}
    >
      <DialogTitle component="div" sx={{ p: 0, mb: 1 }}>
        <ProblemDialogHeader
          handleDialogClose={handleClose}
          tags={details?.tags}
          problemUser={problem.user}
        />
      </DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent
        sx={{
          px: 2,
          pb: 0,
          height: "fit-content",
          overflow: "hidden",
          // borderTop: 0.5,
        }}
        // dividers
      >
        <Grid container spacing={3} height="100%">
          <Grid xs={7} height="100%" sx={{ overflowY: "scroll" }}>
            <ProblemDescription
              title={details?.title}
              description={details?.description}
              htmlToReactParser={htmlToReactParser}
            />
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
          <Grid xs={5} height="100%" pb={0} sx={{ position: "relative" }}>
            <ProblemSolveAction
              dispatch={dispatch}
              htmlToReactParser={htmlToReactParser}
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
              problem={problem}
              tableProblem={tableProblem}
              isLoggedIn={isLoggedIn}
            />
            {!isLoggedIn && (
              <div
                style={{
                  position: "absolute",
                  // top: "50%",
                  // left: "50%",
                  // transform: "translate(-50%, -50%)",
                  top: 0,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // height: "100%",
                    // width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#f8faff",
                    padding: "100px 64px",
                    borderRadius: "8px",
                  }}
                >
                  <LockIcon sx={{ fontSize: "32px" }} />
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    mb={4}
                    mt={2}
                    fontWeight={600}
                    maxWidth={"252px"}
                  >
                    Sign In so you don’t miss your progress!
                  </Typography>
                  {/* <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      postUserSignIn(dispatch, credentialResponse.credential!!);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    // text="signin"
                    size="large"
                    // type="icon"
                    shape="pill"
                  /> */}
                  <CustomGoogleLogin dispatch={dispatch} />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
