import axios from "../config/axios.ts";
import { CombinedTopicsListType } from "../components/pages/problemset/topic-strength.tsx";

type ResponseStrengthType = {
  topic: {
    name: string;
    reference_id: string;
  };
  strength: number;
  question_count: {
    unanswered: number;
    correct: number;
    incorrect: number;
    total: number;
  };
};
type ResponseType = {
  QA: ResponseStrengthType[];
  VARC: ResponseStrengthType[];
  LRDI: ResponseStrengthType[];
};

function convertResponseToTopicStrength(
  res: ResponseType
): CombinedTopicsListType {
  function setTopicList(arrayObj: ResponseStrengthType[]) {
    return arrayObj.map((val) => {
      return {
        topicName: val.topic.name,
        topicId: val.topic.reference_id,
        strengthPercentage: val.strength,
        unansweredValue: val.question_count.unanswered,
        correctValue: val.question_count.correct,
        incorrectValue: val.question_count.incorrect,
        totalValue: val.question_count.total,
      };
    });
  }
  return {
    QA: setTopicList(res["QA"]),
    LRDI: setTopicList(res["LRDI"]),
    VARC: setTopicList(res["VARC"]),
  };
}
export default async function getTopicWiseStrength() {
  return await axios
    .get(`/core/topic_details/`)
    .then((res) => {
      return convertResponseToTopicStrength(res.data);
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}
