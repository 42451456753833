export const UPDATE_SUBJECT_FILTER_ONCLICK = "update_subject_filter_onclick";
export const LOAD_PROBLEM_TABLE_LIST = "load_problem_table_list";
export const UPDATE_PROBLEM_TABLE_PAGE_NUMBER =
  "update_problem_table_page_number";
export const LOAD_PROBLEM_FILTER_DETAILS = "load_problem_filter_details";
export const SELECT_DIFFICULTY_ITEM_FILTER = "select_difficulty_item_filter";
export const SELECT_LIST_ITEM_FILTER = "select_list_item_filter";
export const SELECT_TOPIC_ITEM_FILTER = "select_topic_item_filter";
export const UNSELECT_ALL_TOPIC_FILTER = "unselect_all_topic_filter";
export const SELECT_STATUS_ITEM_FILTER = "select_status_item_filter";
export const TOGGLE_IS_SUGGESTED_FILTER = "toggle_is_suggested_filter";
export const SELECT_ROWS_PER_PAGE_ITEM_FILTER =
  "select_rows_per_page_item_filter";
export const UPDATE_SEARCH_FILTER = "select_search_filter";
export const SELECT_PATTERN_ITEM_FILTER = "select_pattern_item_filter";
export const SET_PROBLEM_TABLE_LOADING = "set_problem_table_loading";

export const SET_ONCLICK_PROBLEM_NEXT_PREVIOUS =
  "set_onclick_problem_next_previous";
// PROBLEM
export const LOAD_PROBLEM_DETAILS = "load_problem_details";
export const SET_PROBLEM_USER = "set_problem_user";
export const UNSET_PROBLEM = "unset_problem";
// export const SET_PROBLEM_MODAL = "set_problem_modal";

// USER
export const LOGIN_USER = "login_user";
export const LOGOUT_USER = "logout_user";
export const SET_USER = "set_user";
export const SET_IS_LOGIN_VALUE = "set_is_login_value";
