import React from "react";
import {
  ProblemDetailsType,
  ProblemUserType,
} from "../../../../store/state-type";
import { Button, Grid, Skeleton, Typography } from "@mui/material";
import { numToAlphabet } from "../../../../utils/num-to-alphabet.ts";
import { SCREEN_STATE } from "./problem-solve-side.tsx";

type MCQOptionsType = {
  options: ProblemDetailsType["options"] | undefined;
  optionSelected: number;
  setOptionSelected: React.Dispatch<React.SetStateAction<number>>;
  htmlToReactParser: any;
  screenState: SCREEN_STATE;
  postSubmit: ProblemUserType["postSubmit"];
};
export default function MCQOptions({
  optionSelected,
  setOptionSelected,
  htmlToReactParser,
  options,
  screenState,
  postSubmit,
}: MCQOptionsType) {
  const correctOptId = postSubmit?.correctAnswer;
  const selectedOptId = postSubmit?.selectedAnswer;
  return (
    <Grid container mt={1}>
      {options && options.length !== 0
        ? options.map((option, idx) => {
            return (
              <Grid xs={12} mb={1}>
                {/* @ts-expect-error [including] */}
                <Button
                  id={`problem-option-${idx}`}
                  variant="text"
                  type={
                    optionSelected === idx ||
                    (screenState === SCREEN_STATE.SOLVED &&
                      (correctOptId === option.id ||
                        selectedOptId === option.id))
                      ? "problem-option-selected"
                      : "problem-option"
                  }
                  typeVariant={
                    screenState === SCREEN_STATE.SOLVED
                      ? correctOptId === option.id
                        ? "correct"
                        : selectedOptId === option.id
                        ? "incorrect"
                        : ""
                      : ""
                  }
                  fullWidth
                  sx={{
                    height: "100%",
                  }}
                  onClick={() => {
                    if (screenState === SCREEN_STATE.UNSOLVED)
                      setOptionSelected((val) => (val === idx ? -1 : idx));
                  }}
                >
                  <Typography
                    component="p"
                    variant="body1"
                    alignSelf="center"
                    mr={2}
                  >
                    {numToAlphabet(idx)})
                  </Typography>
                  <span>{htmlToReactParser.parse(option?.description)}</span>
                </Button>
              </Grid>
            );
          })
        : Array.apply(null, {
            length: 4,
          }).map((__) => (
            <Grid xs={12} mb={1}>
              <Button fullWidth>
                <Skeleton
                  width={"100%"}
                  height={"52px"}
                  variant="rectangular"
                  sx={{ borderRadius: "12px" }}
                />
              </Button>
            </Grid>
          ))}
    </Grid>
  );
}
