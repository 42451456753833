import React from "react";
import SearchInput from "../../common/search-input.tsx";
import TableFilterButton from "../../common/table-filter-button.tsx";
import Stack from "@mui/material/Stack";
import { DispatchType, StateType } from "../../../store/state-type.ts";
import SuggestedFilter from "./filter-menu/suggested-filter.tsx";

type ProblemsetFilterType = {
  filters: StateType["table"]["filters"];
  dispatch: DispatchType;
  isUserLoggedIn: boolean;
};
function ProblemsetFilter({
  filters,
  dispatch,
  isUserLoggedIn,
}: ProblemsetFilterType) {
  const topicList = filters.topicList.filter((topic) => {
    return filters.subjectList.some(
      (subject) =>
        subject.isSelected &&
        (subject.id === "" || subject.id === topic.subjectId)
    );
  });
  return (
    <Stack direction="row" spacing={1} sx={{ mt: 3, mb: 1 }}>
      <TableFilterButton
        name="List"
        filter={filters.listList}
        dispatch={dispatch}
      />
      <TableFilterButton name="Topic" filter={topicList} dispatch={dispatch} />
      <TableFilterButton
        name="Pattern"
        filter={filters.patternList}
        dispatch={dispatch}
      />
      {/* <TableFilterButton
        name="Difficulty"
        filter={filters.difficultyList}
        dispatch={dispatch}
      /> */}
      <TableFilterButton
        name="Status"
        filter={filters.statusList}
        dispatch={dispatch}
        filterDisabled={!isUserLoggedIn}
      />
      <SearchInput
        placeholder="Search questions"
        searchFilter={filters.searchString}
        dispatch={dispatch}
      />
      <SuggestedFilter
        name="Genie Mode"
        filter={filters.isSuggested}
        dispatch={dispatch}
        filterDisabled={!isUserLoggedIn}
      />
    </Stack>
  );
}
export default ProblemsetFilter;
