import axios from "../config/axios.ts";
import { SET_USER } from "../store/action-types.ts";
import { DispatchType, StateType } from "../store/state-type.ts";

type ResponseType = {
  email: string;
  first_name: string;
  profile_picture: string;
};

function convertResponseToUserDetails(
  response: ResponseType
): StateType["user"]["userDetails"] {
  return {
    userFirstName: response.first_name,
    userLasttName: null,
    userEmail: response.email,
    userProfileImage: response.profile_picture,
  };
}
export default function getUserDetails(dispatch: DispatchType) {
  axios
    .get(`/users/profile/`)
    .then((res) => {
      dispatch({
        type: SET_USER,
        value: convertResponseToUserDetails(res.data),
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
