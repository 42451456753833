import React from "react";
import { DispatchType, StateType } from "../../../store/state-type";
import {
  SELECT_LIST_ITEM_FILTER,
  SELECT_PATTERN_ITEM_FILTER,
  SELECT_STATUS_ITEM_FILTER,
  SELECT_TOPIC_ITEM_FILTER,
} from "../../../store/action-types.ts";

/** MUI */
import Chip from "@mui/material/Chip";
import { Stack } from "@mui/material";

type SelectedFilterListType = {
  filters: StateType["table"]["filters"];
  dispatch: DispatchType;
};
export default function SelectedFilterList({
  filters: { listList, topicList, statusList, patternList },
  dispatch,
}: SelectedFilterListType) {
  return (
    <Stack width="100%" direction="row" spacing={1} flexWrap="wrap" rowGap={1}>
      {/* {subjectList
        .filter((val, idx) => val.isSelected && idx !== 0)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {}}
            />
          );
        })} */}
      {listList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch({ type: SELECT_LIST_ITEM_FILTER, index: val.index });
              }}
            />
          );
        })}
      {patternList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch({
                  type: SELECT_PATTERN_ITEM_FILTER,
                  index: val.index,
                });
              }}
            />
          );
        })}
      {topicList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch({ type: SELECT_TOPIC_ITEM_FILTER, index: val.index });
              }}
            />
          );
        })}
      {statusList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch({ type: SELECT_STATUS_ITEM_FILTER, index: val.index });
              }}
            />
          );
        })}
    </Stack>
  );
}
