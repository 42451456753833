import React from "react";
import Typography from "@mui/material/Typography";
import {
  ACCEPTANCE_HIGH,
  ACCEPTANCE_LOW,
} from "../../../../constants/problem-states.ts";

type AcceptanceCellType = { acceptance: number };
export default function AcceptanceCell({ acceptance }: AcceptanceCellType) {
  function setFontColor(acceptance: number) {
    if (acceptance > ACCEPTANCE_HIGH) return "primary.easy";
    else if (acceptance <= ACCEPTANCE_LOW) return "primary.hard";
    else return "primary.medium";
  }
  return (
    <Typography
      variant="body2"
      gutterBottom={false}
      sx={{ color: setFontColor(acceptance) }}
    >
      {acceptance.toFixed(1)}%
    </Typography>
  );
}
