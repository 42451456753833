import React from "react";
import { StyledTableCell, StyledTableRow } from "./styles.tsx";
import { Chip, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import getRandomIntInclusive from "../../../../utils/get-random-int.ts";

import ShareIcon from "@mui/icons-material/Share";

type LoadingRowType = {
  rowsPerPage: number;
};
export default function LoadingRow({ rowsPerPage }) {
  return Array.apply(null, { length: rowsPerPage }).map((_) => (
    <StyledTableRow>
      <StyledTableCell>
        <Skeleton variant="circular">
          <IconButton size="small">
            <ShareIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Skeleton>
      </StyledTableCell>
      <StyledTableCell>
        <Stack spacing={0.5}>
          <Typography variant="body1" gutterBottom={false}>
            <Skeleton animation="wave" />
          </Typography>
          <Stack direction="row" spacing={1}>
            {Array.apply(null, {
              length: getRandomIntInclusive(1, 4),
            }).map((__) => (
              <Skeleton>
                <Chip size="small" label="Loading" />
              </Skeleton>
            ))}
          </Stack>
        </Stack>
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton
          component="p"
          width={"50px"}
          sx={{ margin: "auto" }}
          animation="wave"
        />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton
          component="p"
          width={"50px"}
          sx={{ marginLeft: "auto" }}
          animation="wave"
        />
      </StyledTableCell>
    </StyledTableRow>
  ));
}
