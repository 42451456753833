import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ROWS_PER_PAGE,
} from "../constants/problem-states.ts";
import {
  StateType,
  SubjectListItemType,
  ListListItemType,
  TopicListItemType,
  PatternListItemType,
  StatusListItemType,
} from "../store/state-type";

const removeTrailingAmpersand = (str: string): string => {
  if (str.endsWith("&")) {
    return str.slice(0, -1);
  }
  return str;
};

export default function parseFilterParam(
  rowsPerPage: number,
  currentPage: number,
  subjectList: SubjectListItemType[],
  listList: ListListItemType[],
  topicList: TopicListItemType[],
  patternList: PatternListItemType[],
  statusList: StatusListItemType[],
  searchString: string,
  isSuggested: boolean
): string {
  var queryParam: string = "";

  var subjects: string[] = [];
  subjectList
    .filter((subject) => subject.isSelected && subject.id !== "")
    .map((val) => subjects.push(val.id));
  if (subjects.length !== 0) queryParam += `subjects=${subjects.toString()}&`;

  var topics: string[] = [];
  topicList
    .filter((topic) => topic.isSelected)
    .filter((topic) => {
      return subjectList.some(
        (subject) =>
          subject.isSelected &&
          (subject.id === "" || subject.id === topic.subjectId)
      );
    })
    .map((val) => topics.push(val.id));
  if (topics.length !== 0) queryParam += `topics=${topics.toString()}&`;

  var lists: string[] = [];
  listList.filter((val) => val.isSelected).map((val) => lists.push(val.id));
  if (lists.length !== 0) queryParam += `lists=${lists.toString()}&`;

  var question_types: string[] = [];
  patternList
    .filter((val) => val.isSelected)
    .map((val) => question_types.push(val.id));
  if (question_types.length !== 0)
    queryParam += `question_types=${question_types.toString()}&`;

  var status: string[] = [];
  statusList.filter((val) => val.isSelected).map((val) => status.push(val.id));
  if (status.length !== 0)
    queryParam += `user_question_status=${status.toString()}&`;

  if (searchString !== "") queryParam += `search=${searchString}&`;
  if (isSuggested) queryParam += `is_suggested=${true}&`;

  if (currentPage !== DEFAULT_PAGE_NUMBER) queryParam += `page=${currentPage}&`;
  if (rowsPerPage !== DEFAULT_ROWS_PER_PAGE)
    queryParam += `page_size=${rowsPerPage}`;

  return removeTrailingAmpersand(queryParam);
}
