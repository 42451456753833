import { StateType } from "../store/state-type";

export const NULL_USER: StateType["user"] = {
  isLoading: true,
  isLoggedIn: false,
  userDetails: {
    userFirstName: null,
    userLasttName: null,
    userEmail: null,
    userProfileImage: null,
  },
};
