import {
  initProgressValue,
  UserProgressType,
} from "../components/pages/problemset/progress-card.tsx";
import axios from "../config/axios.ts";

type ResponseType = {
  unanswered: number;
  correct: number;
  incorrect: number;
};
function convertResponseToUserProgress(
  response: ResponseType
): UserProgressType {
  return {
    incorrect: response.incorrect,
    correct: response.correct,
    not_answered: response.unanswered,
  };
}

export default async function getUserProgress(): Promise<UserProgressType> {
  return await axios
    .get(`/users/progress/`)
    .then((res) => {
      return convertResponseToUserProgress(res.data);
    })
    .catch((err) => {
      console.log(err);
      return initProgressValue;
    });
}
