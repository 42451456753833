import { createTheme } from "@mui/material/styles";

const COLOR_PALETTE = {
  primary: {
    lighter: "#ffffff",
    light: "#f8faff",
    main: "#1a64f1",
    dark: "#041527",
    text: "#1e1e1e",
    contrastText: "#ffffff",
    borderColor: "#1e1e1e",
    contrastBorderColor: "#ffffff",
    lightGreen: "#daf7a621",
    green: "#01b328",
    easy: "#00af9b",
    medium: "#ffb800",
    hard: "#ff2d55",
    lightHard: "#ff2d5514",
  },
  secondary: {
    light: "#ff7961",
    main: "#f44336",
    dark: "#ba000d",
    contrastText: "#000",
  },
};

export const theme = createTheme({
  components: {
    // Name of the component
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none!important",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { type: "table-filter" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "1rem",
            borderRadius: "5px",
            justifyContent: "space-between",
          },
        },
        {
          props: { type: "subject-filter" },
          style: {
            fontSize: "1rem",
            fontWeight: "500",
            // lineHeight: "1.25rem",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "flex-start",
            fontSize: "small",
            lineHeight: "1rem",
            maxWidth: "20%",
          },
        },
        {
          props: { variant: "text", type: "subject-filter" },
          style: {
            color: COLOR_PALETTE.primary.text,
            opacity: 0.75,
            borderBottom: `5px solid ${COLOR_PALETTE.primary.light}`,
          },
        },
        {
          props: { variant: "contained", type: "subject-filter" },
          style: {
            color: COLOR_PALETTE.primary.text,
            background: "none",
            borderColor: COLOR_PALETTE.primary.borderColor,
            borderBottom: `5px solid ${COLOR_PALETTE.primary.dark}`,
            boxShadow: "none",
            ":hover": {
              background: "none",
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "contained", type: "problem-modal-submit" },
          style: {
            fontSize: "1rem",
            paddingLeft: "32px",
            paddingRight: "32px",
            borderRadius: "1.25rem",
            color: COLOR_PALETTE.primary.contrastText,
            backgroundColor: COLOR_PALETTE.primary.green,
            borderColor: COLOR_PALETTE.primary.borderColor,
          },
        },
        {
          props: { type: "problem-option" },
          style: {
            textAlign: "left",
            justifyContent: "left",
            alignItems: "flex-start",
            borderRadius: "0.75rem",
            color: COLOR_PALETTE.primary.text,
          },
        },
        {
          props: { type: "problem-option-selected" },
          style: {
            textAlign: "left",
            justifyContent: "left",
            alignItems: "flex-start",
            borderRadius: "0.75rem",
            color: COLOR_PALETTE.primary.text,
            background: COLOR_PALETTE.primary.light,
            border: `1px solid ${COLOR_PALETTE.primary.main}`,
          },
        },
        {
          props: { type: "problem-option-selected", typeVariant: "correct" },
          style: {
            color: COLOR_PALETTE.primary.text,
            background: COLOR_PALETTE.primary.lightGreen,
            border: `1px solid ${COLOR_PALETTE.primary.green}`,
            ":hover": {
              background: COLOR_PALETTE.primary.lightGreen,
            },
          },
        },
        {
          props: { type: "problem-option-selected", typeVariant: "incorrect" },
          style: {
            color: COLOR_PALETTE.primary.text,
            background: COLOR_PALETTE.primary.lightHard,
            border: `1px solid ${COLOR_PALETTE.primary.hard}`,
            ":hover": {
              background: COLOR_PALETTE.primary.lightHard,
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { type: "correct" },
          style: {
            // Root class for the input field
            "& .MuiOutlinedInput-root": {
              // Class for the border around the input field
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${COLOR_PALETTE.primary.green}!important`,
              },
            },
            // Class for the label of the input field
            "& .MuiInputLabel-outlined": {
              color: `${COLOR_PALETTE.primary.green}!important`,
            },
          },
        },
        {
          props: { type: "incorrect" },
          style: {
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${COLOR_PALETTE.primary.hard}!important`,
              },
            },
            "& .MuiInputLabel-outlined": {
              color: `${COLOR_PALETTE.primary.hard}!important`,
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingBottom: "0",
          paddingTop: "0",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#00000085",
        },
      },
    },
  },
  palette: COLOR_PALETTE,
  typography: {
    fontFamily: '"Hanken Grotesk", sans-serif;',
    tableHeader: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1rem",
    },
    tableFilter: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1160,
      lg: 1200,
      xl: 1900,
    },
  },
});

export default theme;
