import axios from "../config/axios.ts";
import {
  QUESTION_TYPE,
  USER_QUESTION_TYPE,
} from "../constants/problem-states.ts";
import { SET_PROBLEM_USER } from "../store/action-types.ts";
import type {
  DispatchType,
  ProblemUserType,
  StateType,
} from "../store/state-type.ts";
import getUserQuestionType from "../utils/get-user-question-type.ts";

type ResponseType = {
  status: "I" | "C";
  time_taken: number;
  question_start_time: string;
  question_end_time: string;
  answer: string;
  solution: string;
  response: string;
};

function convertResultToProblem(result: ResponseType): ProblemUserType {
  return {
    type: getUserQuestionType(result.status),
    timeTaken: result.time_taken,
    questionStartTime: result.question_start_time,
    postSubmit: {
      questionEndTime: result.question_end_time,
      selectedAnswer: result.response,
      correctAnswer: result.answer,
      solution: result.solution,
    },
  };
}

export default async function postSubmitQuestion(
  dispatch: DispatchType,
  questionRefId: string,
  userAnswer: string,
  questionType: QUESTION_TYPE
) {
  if (
    userAnswer === "" ||
    questionRefId === "" ||
    questionType === QUESTION_TYPE.UNKOWN
  ) {
    console.log("Selected Option ID is empty");
    return USER_QUESTION_TYPE.UNKOWN;
  }
  const data =
    questionType === QUESTION_TYPE.SINGLE_CORRECT
      ? {
          option_reference_id: userAnswer,
        }
      : { answer_text: userAnswer };
  return await axios
    .post(`/core/questions/${questionRefId}/submit`, data)
    .then((res) => {
      const convertesResult = convertResultToProblem(res.data);
      dispatch({
        type: SET_PROBLEM_USER,
        value: convertesResult,
      });
      return convertesResult.type;
    })
    .catch((err) => {
      console.log(err);
      return USER_QUESTION_TYPE.UNKOWN;
    });
}
