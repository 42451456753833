import axios from "axios";
// import { store } from "./initialStore";

// const baseURL = "http://172.17.62.19:8000";
// const baseURL = "http://localhost:8000/backend";
// const baseURL = "http://15.206.206.188";
// const wsURL = "https://smartninja.in/backend";
const baseURL = "https://prepgenie.in/backend";
// const wsURL = "https://8aed-2401-4900-8838-b608-e855-5ea1-c687-d33a.ngrok-free.app/backend";
const testURL =
  "https://dd55-2401-4900-8838-b608-40d4-3892-6a28-462a.ngrok-free.app/backend";
// const baseURL = 'http://0a2a-183-83-215-8.ngrok.io'
// const baseURL = process.env.REACT_APP_BASE_BACKEND_URL;
// const wsURL = process.env.REACT_APP_WS_BACKEND_URL;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    // let access = store.getState().auth.access;
    let access = null;
    if (access) {
      config.headers["Authorization"] = "Bearer " + access;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      let status = error.response.status;
      let message = error.response.data;
      if (status === 401 || status === 404) return Promise.reject(); // do not show snackbar to user
      if (Math.floor(status / 100) === 4)
        return Promise.reject({ message: message, status: status });
      if (Math.floor(status / 100) === 5)
        return Promise.reject("Unexpected error occured");
    }
    return Promise.reject(error.message);
  }
);

const instanceWithoutHeaders = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instanceWithoutHeaders.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      let status = error.response.status;
      let message = error.response.data;
      if (status / 100 === 4) return Promise.reject(message);
      if (status / 100 === 5) return Promise.reject("Unexpected error occured");
    }
    return Promise.reject(error.message);
  }
);

export { instance as default, instanceWithoutHeaders, baseURL };
