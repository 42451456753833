import axios from "../config/axios.ts";
import { LOGIN_USER } from "../store/action-types.ts";
import type { DispatchType, StateType } from "../store/state-type.ts";

type ResponseType = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  profile_picture: string;
};

function convertResultToUserDetails(result: ResponseType): StateType["user"] {
  return {
    isLoading: false,
    isLoggedIn: true,
    userDetails: {
      userFirstName: result.given_name,
      userLasttName: result.family_name,
      userEmail: result.email,
      userProfileImage: result.profile_picture,
    },
  };
}

export default function postUserSignIn(
  dispatch: DispatchType,
  firebaseId: string
) {
  if (firebaseId == null) {
    console.log("Email ID is NULL");
    return;
  }
  const data = {
    firebase_id: firebaseId,
  };
  axios
    .post(`/users/login/`, data)
    .then((res) => {
      dispatch({
        type: LOGIN_USER,
        value: convertResultToUserDetails(res.data),
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
