import axios from "../config/axios.ts";
import { LOAD_PROBLEM_DETAILS } from "../store/action-types.ts";
import type { DispatchType, StateType } from "../store/state-type.ts";
import getQuestionType from "../utils/get-question-type.ts";
import getUserQuestionType from "../utils/get-user-question-type.ts";

type ResponseTagType = { name: string };
type ResponseOptionsType = {
  description: string;
  reference_id: string;
};
type ResponseType = {
  description: string;
  options: ResponseOptionsType[];
  tags: ResponseTagType[];
  reference_id: string;
  question_type: string;
  title: string;
  user_question: {
    status: string;
    time_taken: number;
    question_start_time: string;
    question_end_time: string | null;
    response?: string;
    solution?: string;
    answer?: string;
  } | null;
};

function convertResultToProblem(result: ResponseType): StateType["problem"] {
  return {
    details: {
      id: result.reference_id,
      questionType: getQuestionType(result.question_type),
      title: result.title,
      description: result.description,
      tags: result.tags.map((tag: ResponseTagType, tagIdx: number) => {
        return {
          name: tag.name,
        };
      }),
      options: result.options.map(
        (option: ResponseOptionsType, optionIdx: number) => {
          return {
            description: option.description,
            id: option.reference_id,
          };
        }
      ),
    },
    user: result.user_question
      ? {
          type: getUserQuestionType(result.user_question.status),
          timeTaken: result.user_question.time_taken,
          questionStartTime: result.user_question.question_start_time,
          postSubmit: result.user_question.response
            ? {
                questionEndTime: result.user_question.question_end_time ?? "",
                selectedAnswer: result.user_question.response ?? "",
                correctAnswer: result.user_question.answer ?? "",
                solution: result.user_question.solution ?? "",
              }
            : undefined,
        }
      : undefined,
  };
}

export default function fetchProblemDetails(
  dispatch: DispatchType,
  problemId: string,
  isUserLoggedIn: boolean
) {
  if (problemId == null) {
    console.log("Problem ID is NULL");
    return;
  }
  axios
    .get(`/core/questions/${problemId}`)
    .then((res) => {
      dispatch({
        type: LOAD_PROBLEM_DETAILS,
        value: convertResultToProblem(res.data),
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
