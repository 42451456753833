import axios from "../config/axios.ts";
import { LOAD_PROBLEM_FILTER_DETAILS } from "../store/action-types.ts";
import { subjectList } from "../store/initial-state.ts";
import type {
  DispatchType,
  ListListItemType,
  PatternListItemType,
  SubjectListItemType,
  TopicListItemType,
} from "../store/state-type.ts";
import stringToArrayParams from "../utils/string-to-array-param.ts";

type ResponseType = {
  subjects: { name: string; reference_id: string }[];
  topics: { [x: string]: { name: string; reference_id: string }[] };
  question_types: { name: string; reference_id: string }[];
  lists: { name: string; reference_id: string }[];
  user_question_status: { name: string; reference_id: string }[];
};

type FilterListType = {
  listList: ListListItemType[];
  subjectList: SubjectListItemType[];
  topicList: TopicListItemType[];
  patternList: PatternListItemType[];
};

function convertResultToFilterList(
  result: ResponseType,
  searchParams: URLSearchParams
): FilterListType {
  const subjectsParam: string | null = searchParams.get("subjects");
  const topicsParam: string | null = searchParams.get("topics");
  const patternParam: string | null = searchParams.get("question_types");
  const listsParam: string | null = searchParams.get("lists");

  var topicList: TopicListItemType[] = [];
  var idxCount: number = 0;
  Object.keys(result.topics).map((key) =>
    result.topics[key].map((topic) => {
      topicList.push({
        name: topic.name,
        id: topic.reference_id,
        index: idxCount,
        isSelected: stringToArrayParams(topicsParam).includes(
          topic.reference_id
        ),
        subjectId: key,
      });
      idxCount++;
    })
  );

  var newSubjectList: SubjectListItemType[] = subjectList.map((subject) => {
    var tempSubList = result.subjects.filter(
      (val) => val.name === subject.name
    );
    if (tempSubList.length === 0) {
      return {
        ...subject,
        isSelected:
          subject.name === "All" && !!!subjectsParam?.length ? true : false,
      };
    }
    return {
      ...subject,
      id: tempSubList[0].reference_id,
      isSelected: stringToArrayParams(subjectsParam).includes(
        tempSubList[0].reference_id
      ),
    };
  });

  return {
    listList: result.lists.map((list, listIdx) => {
      return {
        index: listIdx,
        id: list.reference_id,
        name: list.name,
        isSelected: stringToArrayParams(listsParam).includes(list.reference_id),
      };
    }),
    subjectList: newSubjectList,
    topicList: topicList,
    patternList: result.question_types.map((pattern, patternIdx) => {
      return {
        index: patternIdx,
        id: pattern.reference_id,
        name: pattern.name,
        isSelected: stringToArrayParams(patternParam).includes(
          pattern.reference_id
        ),
      };
    }),
  };
}

export default function fetchProblemTableFilterDetails(
  dispatch: DispatchType,
  searchParams: URLSearchParams
) {
  axios
    .get(`/core/content/`)
    .then((res) => {
      dispatch({
        type: LOAD_PROBLEM_FILTER_DETAILS,
        value: convertResultToFilterList(res.data, searchParams),
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
