import firebase from 'firebase/app';

const firebaseConfig: firebase.FirebaseOptions = {
  apiKey: "AIzaSyBsCXhjIkbTzWKXm2XG7BBKdZClENDQGe4",
  authDomain: "prepgenie-c57b0.firebaseapp.com",
  projectId: "prepgenie-c57b0",
  storageBucket: "prepgenie-c57b0.appspot.com",
  messagingSenderId: "1023092153099",
  appId: "1:1023092153099:web:4405b80e8dea10982e200e",
  measurementId: "G-LQ82CXETSG"
};

export default firebaseConfig;  