import React from "react";
import {
  DispatchType,
  DifficultyListItemType,
} from "../../../../store/state-type";
import { SELECT_DIFFICULTY_ITEM_FILTER } from "../../../../store/action-types.ts";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";

type DifficultyMenuType = {
  difficultyList: DifficultyListItemType[];
  dispatch: DispatchType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.RefObject<HTMLButtonElement>;
};

export default function DifficultyMenu({
  difficultyList,
  dispatch,
  open,
  setOpen,
  anchorRef,
}: DifficultyMenuType) {
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleOnClickMenuItem = (
    event: Event | React.SyntheticEvent,
    difficultyItemIdx: number
  ) => {
    dispatch({
      type: SELECT_DIFFICULTY_ITEM_FILTER,
      index: difficultyItemIdx,
    });
    // handleClose(event);
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{ zIndex: 5 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper sx={{ my: 0.5 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="difficulty-menu"
                aria-labelledby="difficulty-button"
                onKeyDown={handleListKeyDown}
                sx={{ width: "150px" }}
              >
                {difficultyList.map((difficultyItem, difficultyItemIdx) => {
                  return (
                    <MenuItem
                      id={difficultyItem.name}
                      onClick={(e) =>
                        handleOnClickMenuItem(e, difficultyItemIdx)
                      }
                      disableRipple
                    >
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: difficultyItem.textColor ?? "primary.text",
                        }}
                      >
                        {difficultyItem.name}
                      </Typography>
                      {difficultyItem.isSelected && (
                        <CheckIcon
                          sx={{
                            ml: "auto",
                            fontSize: "18px",
                            color: "primary.main",
                          }}
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
